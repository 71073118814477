import {Box, HStack, Text} from '@chakra-ui/react';
import logo from '../assets/Xsports-Logo.png';


function Header() {
    return (
        <Box bg="blue.500" p={2} textAlign="center">
            <HStack justify="center">
                <img src={logo} alt="XSports Logo" style={{ height: '80px' }} />
            </HStack>
        </Box>
    );
}

export default Header;
