import { Box, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
    return (
        <Box mt={8} textAlign="center" bg="gray.200" py={4}>
            <Text fontSize="sm" color="gray.500">
                © XSports 2023 |
                <RouterLink to="#">
                    <Text as="span" mx={2} color="blue.500">Terms & Conditions</Text>
                </RouterLink>
                |
                <RouterLink to="#">
                    <Text as="span" mx={2} color="blue.500">Return & Refund Policy</Text>
                </RouterLink>
                | Social Media Links:
                <Text as="span" mx={2}>
                    <a href="https://www.facebook.com/profile.php?id=100092268724610&mibextid=LQQJ4d">Facebook</a>
                </Text> &
                <Text as="span" mx={2}>
                    <a href="https://www.instagram.com/ten.by.twenty.botswana/">Instagram</a>
                </Text>
                | Developed by <a href="https://weblogic.co.bw/">Weblogic</a>
            </Text>
        </Box>
);
}

export default Footer;
