import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import TermsAndConditions from "./Components/TermsAndConditions";
import ReturnsAndExchanges from "./Components/ReturnsAndExchanges";

function App() {
  return (
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/return-policy" element={<ReturnsAndExchanges />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
