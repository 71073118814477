import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text } from '@chakra-ui/react';

function ReturnsAndExchanges() {
    return (
        <Box w="100%" p={4}>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={4}>
                Returns & Exchanges Policy
            </Text>

            <Accordion allowMultiple>
                {/* Section 1 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                30-Day Return Policy
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        We offer a 30-day policy for returns and exchanges, meaning you have a month from when you receive your item to
                        request a return or exchange. Please note that items such as Rackets, Balls, Grips, and Gift cards cannot be returned.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 2 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Eligibility for Return or Exchange
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        For a return or exchange, your item must be in its original packaging, unused and unworn, with tags still attached.
                        You must also provide a receipt or proof of purchase.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 3 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Return or Exchange Process
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        To initiate a return or exchange, please email us at info@10by20padel.co.bw and bring your item to our ProShop
                        at 10by20 The Fields, The Fields Mall, Gaborone, Botswana. We will not accept any items sent back to us without prior notification.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 4 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Inspection and Notification
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Once we receive and inspect your item, we will notify you by email. Please inspect your order upon receipt, and contact
                        us immediately if there are any issues such as defects, damage, or incorrect items.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 5 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Refund Processing
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Once we have inspected your returned item, we will notify you of the approval or rejection of your refund. If your refund is
                        approved, it will be processed through our third-party payment system and may take up to five working days to appear on
                        your credit card or original method of payment.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 6 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Non-Returnable Items
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Please note that refunds and exchanges are not available for Rackets, Balls, Grips, or Gift Cards unless they have a
                        manufacturing defect.
                    </AccordionPanel>
                </AccordionItem>

                {/* Add more sections as needed */}
            </Accordion>
        </Box>
    );
}

export default ReturnsAndExchanges;
