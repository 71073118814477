import { Box, Text, Button, VStack, HStack, Icon, Link, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { FaGooglePlay, FaApple, FaFootballBall, FaDollarSign, FaPeopleArrows, FaCreditCard } from 'react-icons/fa';
import { GiTennisCourt } from 'react-icons/gi';
import { MdEventAvailable } from 'react-icons/md';

// Define reusable styled headings and buttons
const StyledHeading = ({ children }) => (
    <Text fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold" textAlign="center" color="blue.600" mb={4}>
        {children}
    </Text>
);

const DownloadButton = ({ children, icon, href, colorScheme }) => (
    <Link href={href} isExternal>
        <Button
            leftIcon={<Icon as={icon} />}
            colorScheme={colorScheme}
            size={{ base: 'xs', md: 'md' }}
            variant="solid"
            _hover={{ bg: `${colorScheme}.500`, transform: 'scale(1.05)' }}
            _active={{ bg: `${colorScheme}.600`, transform: 'scale(1.03)' }}
            transition="0.2s"
            shadow="lg"
        >
            {children}
        </Button>
    </Link>
);

function Home() {
    return (
        <Box w="100%" minH="100vh" p={6} display="flex" justifyContent="center" alignItems="center" bg="gray.100">
            {/* Main Content Wrapper */}
            <Box maxW="800px" mx="auto" bg="white" p={10} mt={8} borderRadius="lg" shadow="2xl" overflow="hidden">

                <StyledHeading>Welcome to First Capital Bank Sports Village!</StyledHeading>
                <Text mt={2} fontSize={{ base: "md", md: "lg" }} textAlign="center" color="gray.700" mb={8}>
                    We are thrilled to announce the launch of our much-awaited Centre Court app for booking reservations for Padel and Football! 🎉
                </Text>

                {/* Feature List Using Chakra Alerts with custom background for AlertDescription */}
                <VStack align="start" spacing={5} mb={10}>
                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={GiTennisCourt} color="pink.500" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Book official Padel matches and earn a NPRP rating.
                        </AlertDescription>
                    </Alert>

                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={FaFootballBall} color="blue.400" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Reserve Football slots hassle-free.
                        </AlertDescription>
                    </Alert>

                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={FaDollarSign} color="orange.400" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Split payments among friends for a single reservation.
                        </AlertDescription>
                    </Alert>

                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={FaPeopleArrows} color="purple.400" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Create open Game Matches for those without partners.
                        </AlertDescription>
                    </Alert>

                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={MdEventAvailable} color="orange.400" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Sign up for Americanos and tournaments right from your phone.
                        </AlertDescription>
                    </Alert>

                    <Alert status="info" variant="left-accent" borderRadius="md" bg="gray.50">
                        <AlertIcon as={FaCreditCard} color="blue.400" />
                        <AlertDescription fontSize={{ base: "md", md: "lg" }} bg="gray.50">
                            Load and receive club credits on the app, with secure credit card details.
                        </AlertDescription>
                    </Alert>
                </VStack>

                {/* Download Buttons */}
                <Box textAlign="center" mt={10}>
                    <Text fontWeight="bold" fontSize="lg" color="gray.800" mb={4}>BOOKINGS NOW OPEN ON THE APP FROM MAY 1 2024.</Text>
                    <HStack justifyContent="center" spacing={4}>
                        <DownloadButton href="https://play.google.com/store/apps/details?id=com.playbypoint.centrecourt" icon={FaGooglePlay} colorScheme="green">
                            Download for Android
                        </DownloadButton>
                        <DownloadButton href="https://apps.apple.com/za/app/centre-court-app/id6478928294" icon={FaApple} colorScheme="blue">
                            Download for iOS
                        </DownloadButton>
                    </HStack>
                </Box>

                {/* Slogan */}
                <Box textAlign="center" mt={8}>
                    <Text fontSize="lg" fontWeight="medium" color="blue.500">
                        See you on the courts!
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}

export default Home;
