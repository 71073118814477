import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text } from '@chakra-ui/react';

function TermsAndConditions() {
    return (
        <Box w="100%" p={4}>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={4}>
                Terms & Conditions
            </Text>

            <Accordion allowMultiple>
                {/* Section 1 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Section 1 – Online Booking Platform Terms
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        These terms apply to our online booking platform. By agreeing to them, you confirm that you are of legal age
                        to make bookings in your jurisdiction or that you have given us consent for any minor dependents to use the platform.
                        It is prohibited to use our platform for any illegal or unauthorized purposes, and you must not violate any laws, including
                        copyright laws, when using our service.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 2 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Section 2 – General Conditions
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        We reserve the right to deny access to the platform to anyone, for any reason, at any time. You acknowledge that any content
                        you provide on our site (excluding personal and payment information) may be transmitted unencrypted and may require changes
                        to conform to connecting networks or devices. However, personal and payment information is always encrypted during transmission
                        over networks.
                    </AccordionPanel>
                </AccordionItem>

                {/* Section 3 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Section 3 – Accuracy, Completeness, and Timeliness of Information
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        We strive to provide accurate and up-to-date information on the platform, but we cannot be held responsible if the information
                        provided is inaccurate, incomplete, or outdated. The content on the platform is intended for general information purposes only,
                        and it is recommended that you consult other sources for more accurate, complete, and timely information before making decisions.
                        Your reliance on the content of this platform is entirely at your own risk.
                    </AccordionPanel>
                </AccordionItem>

                {/* Add additional sections below in the same format */}
                {/* Section 4 */}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Section 4 – Changes to the Service and Prices
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        At X-Sports, we may adjust the prices of our products without prior notice, and we retain the right to modify or discontinue
                        the Service or any portion of it without notice. We shall not be held responsible to you or any third party for any changes in
                        price, modifications, suspensions, or terminations of the Service.
                    </AccordionPanel>
                </AccordionItem>

                {/* Continue with additional sections in the same manner */}
            </Accordion>
        </Box>
    );
}

export default TermsAndConditions;
